<template>
  <AppSignUp
    class="mt-13"
    origin="event-signup">
    <template #before-form>
      <div class="d-flex justify-center mb-4">
        <v-img
          max-height="100"
          src="@/assets/icons/boston-lights.png"
          contain />
      </div>
    </template>

    <template #after-form>
      <div class="d-flex justify-center mt-6">
        <v-img
          max-height="50"
          src="@/assets/icons/SOURCERY_poweredby_web.png"
          contain />
      </div>
    </template>
  </AppSignUp>
</template>

<script>
export default {
  name: 'EventSignUp',
  components: {
    AppSignUp: () => import('@/views/SignUp'),
  },
};
</script>